
<template>
  <config-code apiname="texture" idname="textureId" labelname="รหัสชนิด"></config-code>
</template>

<script>
import ConfigCode from "./ConfigCode.vue";
export default {
  components: {
    ConfigCode
  }
};
</script>

